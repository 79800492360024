
.sticky-cart {
    position:-webkit-sticky;
    position:sticky;
    bottom:0px;
    border-top: 2px solid var(--secondary-color);
    background-color: var(--content-background-color);
    z-index: 50;
}
.sticky-cart.opened .summary {
    padding-bottom: 10px;
}
.sticky-cart .summary .container-md {
    display:flex;
    flex-wrap: wrap;
}
.sticky-cart .summary .title, .sticky-cart .summary .total {
    text-transform: uppercase;
    line-height: 34px;
    font-weight: 600;
}
.sticky-cart .summary .title a.details i:first-child {
    margin-right:10px;
}
.sticky-cart .summary .title a.details i:last-child {
    margin-left:10px;
    transition: 0.3s ease all;
}
.sticky-cart.opened .summary .title a.details i:last-child {
    transform: rotate(180deg);
}
.sticky-cart .summary .total {
    flex-grow:1;
    text-align: center;
}
@media (max-width: 575.98px) {
.sticky-cart .summary {
        padding-bottom: 10px;
}
.sticky-cart .summary .title {
        flex-grow: 1;
}
.sticky-cart .summary .total {
        flex-grow: 0;
}
.sticky-cart .summary .button {
        width:100%;
}
}
.sticky-cart .details .empty {
    padding:10px;
    text-align: center;
}
.sticky-cart .details .table th {
    border-top:0px;
}


